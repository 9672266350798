import { allDataSince, toPlot} from "./plots";
import { Data, newPlot } from "plotly.js-dist-min";

function showPlot(divName: "plot", data: Array<Data>) {
  const d = document.getElementById(divName);
  newPlot(d, data,  { margin: { t: 0 } })
}

function setMeasurement(divName: string, mName: string, mValue: number) {
  const d = document.getElementById(divName);
  d.textContent = `${mName}: ${mValue}`
}

const sensors = ["120V Greenhouse be680","120V outdoor SHT31"]

allDataSince(sensors, 24).then( (data) => {
  const gh = data["120V Greenhouse be680"]
  const os = data["120V outdoor SHT31"]
  setMeasurement("gh", "Greenhouse", gh[gh.length - 1].channels[0] * 9 / 5 + 32)
  setMeasurement("bs", "Outside", os[os.length - 1].channels[0]  * 9 / 5 + 32)
  showPlot("plot", [toPlot(gh, "Greenhouse"), toPlot(os, "Outside")])
} );
