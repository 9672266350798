import { Data } from "plotly.js-dist-min";


const hour = 60 * 60 * 1000

interface Location {
  longitude: number,
  latitude: number,
}

interface Measurement {
  sensor: string,
  time: number,
  location?: string, 
  channels: Array<number> 
}

export function toPlot(data: Array<Measurement>, name: string): Data {
  const now = Date.now()
  const times = data.map((m) =>  (m.time - now) / hour )
  const temps = data.map((m) => (m.channels)[0] * 9 / 5 + 32)
  return {
      x: times,
      y: temps,
      name: name
     };
}

export async function dataSince(name: string, sinceHoursAgo: number): Promise<Array<Measurement>> {
  const since = Date.now() - ( hour * sinceHoursAgo )
  const uri = encodeURI(`https://symbolscope.com/sensors/measurements/${name}?from=${since}`)
  const response = await fetch(uri)
  const data = (await response.json()) as Array<Measurement>
  if (data.length == 0) {
    return [{ sensor: name, time: -1, channels: [] }]
  }
  return data
}

export async function allDataSince(names: Array<string>, sinceHoursAgo: number): Promise<Map<string, Array<Measurement>>> {
  var m = new Map<string, Array<Measurement>>();
  for (var n of names) {
    m[n] = await dataSince(n, sinceHoursAgo)
  }
  return m
}